/*!
  * @onoffcanvas/core (https://github.com/onokumus/onoffcanvas-monorepo)
  * MIT
  */
@import "variables";

.onoffcanvas-container {
  position: relative;
  overflow: hidden;
}

.onoffcanvas {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  box-sizing: border-box;
  pointer-events: none;
  z-index: $onoffcanvasZindex;
  transform: translate3d(-100%, 0, 0);
  transition: all $transitionTime $transitionFunction;
  &.is-open {
    pointer-events: auto;
  }
}

.onoffcanvas.is-fixed {
  backface-visibility: hidden;
  position: fixed;
}

.onoffcanvas.is-center {
  bottom: auto;
  right: auto;
  left: 50%;
  transform: translate3d(-50%, -100%, 0);
}

.onoffcanvas.is-center.is-open {
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.onoffcanvas.is-top {
  bottom: auto;
  transform: translate3d(0, -100%, 0);
}

.onoffcanvas.is-bottom {
  top: auto;
  transform: translate3d(0, 100%, 0);
}

.onoffcanvas.is-right {
  left: auto;
  transform: translate3d(100%, 0, 0);
}

.onoffcanvas.is-left {
  right: auto;
  transform: translate3d(-100%, 0, 0);
}

.onoffcanvas.is-open {
  transform: translate3d(0, 0, 0);
}

.onoffcanvas-container:hover .onoffcanvas.is-hoverable {
  transform: translate3d(0, 0, 0);
}

.onoffcanvas-container:hover .onoffcanvas.is-center.is-hoverable {
  top: 50%;
  transform: translate3d(0, -50%, 0);
}

.onoffcanvas-toggler {
  position: relative;
  display: inline-block;
  min-width: $toggleBtnSize;
  min-height: $toggleBtnSize;
  cursor: pointer;
  padding: 0;
  vertical-align: middle;
  white-space: nowrap;
}

button.onoffcanvas-toggler {
  border-width: 0;
  background: transparent;
}

.onoffcanvas-toggler::after,
.onoffcanvas-toggler::before {
  content: "";
  position: absolute;
  width: 80%;
  left: 10%;
  top: 50%;
  border-style: solid;
  border-color: $toggleBtnBorderColor;
  transform: translate3d(0, -50%, 0);
  transition: all $transitionTime $transitionFunction;
}

.onoffcanvas-toggler:after {
  border-width: $toggleBtnBorderWidth 0;
  height: 50%;
}

.onoffcanvas-toggler:before {
  border-width: $toggleBtnBorderWidth 0 0 0;
}

// .is-open .onoffcanvas-toggler[aria-expanded="true"]::after,
// .is-open .onoffcanvas-toggler[aria-expanded="true"]::before {
//   width: 100%;
//   height: $toggleBtnBorderWidth;
//   left: 0;
// }
//
// .is-open .onoffcanvas-toggler[aria-expanded="true"]::before {
//   transform: rotate(45deg);
// }
//
// .is-open .onoffcanvas-toggler[aria-expanded="true"]::after {
//   transform: rotate(-45deg);
//   border-width: $toggleBtnBorderWidth 0 0 0;
// }

.is-open .onoffcanvas-toggler[aria-expanded="true"],
.onoffcanvas-toggler.is-animated[aria-expanded="true"] {
  &::before,
  &::after {
    width: 100%;
    height: $toggleBtnBorderWidth;
    left: 0;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
    border-width: $toggleBtnBorderWidth 0 0 0;
  }
}

.onoffcanvas-drawer {
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  contain: strict;
  z-index: 5;

  &.is-open {
    pointer-events: auto;
  }
}
