/*!
  * @onoffcanvas/core (https://github.com/onokumus/onoffcanvas-monorepo)
  * MIT
  */
.onoffcanvas-container {
  position: relative;
  overflow: hidden;
}

.onoffcanvas {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  box-sizing: border-box;
  pointer-events: none;
  z-index: 1001;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  transition: all 0.3s ease;
}

.onoffcanvas.is-open {
  pointer-events: auto;
}

.onoffcanvas.is-fixed {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: fixed;
}

.onoffcanvas.is-center {
  bottom: auto;
  right: auto;
  left: 50%;
  -webkit-transform: translate3d(-50%, -100%, 0);
  transform: translate3d(-50%, -100%, 0);
}

.onoffcanvas.is-center.is-open {
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.onoffcanvas.is-top {
  bottom: auto;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.onoffcanvas.is-bottom {
  top: auto;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

.onoffcanvas.is-right {
  left: auto;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.onoffcanvas.is-left {
  right: auto;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.onoffcanvas.is-open {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.onoffcanvas-container:hover .onoffcanvas.is-hoverable {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.onoffcanvas-container:hover .onoffcanvas.is-center.is-hoverable {
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

.onoffcanvas-toggler {
  position: relative;
  display: inline-block;
  min-width: 2.5rem;
  min-height: 2.5rem;
  cursor: pointer;
  padding: 0;
  vertical-align: middle;
  white-space: nowrap;
}

button.onoffcanvas-toggler {
  border-width: 0;
  background: transparent;
}

.onoffcanvas-toggler::after,
.onoffcanvas-toggler::before {
  content: "";
  position: absolute;
  width: 80%;
  left: 10%;
  top: 50%;
  border-style: solid;
  border-color: #adb5bd;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
  transition: all 0.3s ease;
}

.onoffcanvas-toggler:after {
  border-width: 2px 0;
  height: 50%;
}

.onoffcanvas-toggler:before {
  border-width: 2px 0 0 0;
}

.is-open .onoffcanvas-toggler[aria-expanded="true"]::before, .is-open .onoffcanvas-toggler[aria-expanded="true"]::after,
.onoffcanvas-toggler.is-animated[aria-expanded="true"]::before,
.onoffcanvas-toggler.is-animated[aria-expanded="true"]::after {
  width: 100%;
  height: 2px;
  left: 0;
}

.is-open .onoffcanvas-toggler[aria-expanded="true"]::before,
.onoffcanvas-toggler.is-animated[aria-expanded="true"]::before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.is-open .onoffcanvas-toggler[aria-expanded="true"]::after,
.onoffcanvas-toggler.is-animated[aria-expanded="true"]::after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-width: 2px 0 0 0;
}

.onoffcanvas-drawer {
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  contain: strict;
  z-index: 5;
}

.onoffcanvas-drawer.is-open {
  pointer-events: auto;
}
/*# sourceMappingURL=onoffcanvas.css.map */